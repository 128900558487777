<template>
  <div class="w-1200">
    <div class="boost-center-list">
      <div class="boost-center-item" v-for="item in boostCenterList" :key="item.id"
            @click="toProduct(item)">
        <div>
          <img style="width: 200px;height: 200px" v-lazy="item.img.img"/>
        </div>
        <div>{{item.productName}}</div>
        <div>价格：{{item.price}}</div>
        <div>助力可减：{{item.boostPrice}}</div>
        <div>结束时间：{{item.activityAfter}}</div>
        <div>
          <div v-if="item.isUsed">已发起</div>
          <div v-else>发起拼团</div>
        </div>
      </div>
    </div>

    <!-- 分页区域 -->
    <el-pagination
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { boostCenterListUrl } from '@/api/index'

export default {
  name: 'BoostCenter',
  data () {
    return {
      queryInfo: {
        status: 1,
        page: 1,
        pageSize: 10
      },
      total: 0,
      boostCenterList: []

    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getBoostCenterList()
    },
    // 获取助力商品列表
    async getBoostCenterList () {
      const { data: res } = await this.$http.get(boostCenterListUrl, { params: this.queryInfo })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.boostCenterList = res.data.list
      this.total = res.data.total
    },
    // 跳转到商品详情页
    toProduct(boostInfo){
      this.$router.push({ path: '/product', query: { pageStatus: 'boost',boostId:boostInfo.id }})
    },
    // 分页页码显示条数
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getBoostCenterList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.getBoostCenterList()
    },
  }
}
</script>

<style scoped>

</style>
